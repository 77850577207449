import { RestTicketModel } from '@shared/interfaces/turnover.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { HumanizePipe } from '@shared/pipes/humanize.pipe';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import { InspectionAreaModel } from './inspection-area.model';
import { InspectionStepModel } from './inspection-step.model';
import {
  InspectionRecurrencePeriod,
  InspectionStatus,
  InspectionType,
  RestInspectionsModel,
  ScoringModels,
} from './rest-inspections-model.interface';

export declare type PropertyState = 'good' | 'fair' | 'poor' | 'skipped' | undefined;

export declare type InspectionBackUrl = 'inspections/resident' | 'inspections/user';

export const InspectionBaseUrlParamsKeys = {
  backInspectionUrl: 'backInspectionUrl',
};

export class InspectionModel {
  readonly id: number;
  readonly completedOrSkippedAreasCount: number;
  readonly areasCount: number;
  readonly title: string;
  readonly status: string;
  readonly inspectionType: string;
  readonly areas: InspectionAreaModel[];

  readonly summary: { [key: string]: number } = { flagsCount: 0 };
  readonly allSteps: InspectionStepModel[];
  readonly propertyId: number;
  readonly portfolioId: number;
  readonly unitName: string;
  readonly unitId: number;
  readonly turnoverId: number | undefined;
  readonly isBulk: boolean;
  readonly isResident: boolean;
  readonly parentInspectionId: number;
  readonly dynamicInspectionId: number;
  readonly scoringModel?: ScoringModels;
  readonly resultScore?: number;
  reviewer: RestUserModel | null;
  completedByUser: RestUserModel | null;

  propertyName: string;
  readonly recurrencePeriod?: InspectionRecurrencePeriod;
  readonly isArchived: boolean;

  constructor(
    public readonly entityDetails: RestInspectionsModel,
    public readonly inspectionTemplate: RestTemplateModel,
    tickets?: RestTicketModel[],
    users?: Map<number, RestUserModel>
  ) {
    this.id = entityDetails.id;
    this.inspectionType =
      entityDetails.inspectionType != null
        ? HumanizePipe.transform(InspectionType[entityDetails.inspectionType])
        : 'Inspection';

    if (entityDetails.inspectionType === InspectionType.CustomInspection && entityDetails.inspectionTemplate) {
      this.inspectionType = entityDetails.inspectionTemplate?.name || 'Inspection';
    }

    this.title = `${this.inspectionType} - ${entityDetails.unitId ? entityDetails.unit.name : 'bulk'}`;
    this.status = HumanizePipe.transform(InspectionStatus[entityDetails.status]);
    this.propertyId = entityDetails.unit?.propertyId || entityDetails.propertyId;
    this.portfolioId = entityDetails.unit?.property?.portfolioId;
    this.isBulk = entityDetails?.isBulk;
    this.isResident = entityDetails?.isResident;
    this.parentInspectionId = entityDetails?.parentInspectionId;
    this.dynamicInspectionId = entityDetails?.dynamicInspectionId;
    this.resultScore = entityDetails?.resultScore;
    this.scoringModel = entityDetails?.scoringModel;
    this.unitId = entityDetails?.unitId;
    this.turnoverId = entityDetails?.unit?.turnoverId || undefined;
    this.unitName =
      entityDetails.unit?.name ||
      (entityDetails.bulkUnitIds.length == 0 ? 'All ' : entityDetails.bulkUnitIds.length) + ' units';
    this.recurrencePeriod = entityDetails.recurrencePeriod;
    this.propertyName = entityDetails?.unit?.property?.name;

    if (entityDetails?.inspectionContents.length) {
      this.areas = entityDetails.inspectionContents.map(
        content => new InspectionAreaModel(content, tickets?.filter(t => t.inspectionContentId === content.id) || [])
      );
      this.allSteps = this.areas.flatMap(area => area.steps).filter(Boolean);

      this.summary = this.allSteps.reduce((acc, curStep) => {
        acc[curStep.propertyState] = (acc[curStep.propertyState] ?? 0) + 1;
        acc.notesCount = (acc.notesCount ?? 0) + (curStep.note ? 1 : 0);
        return acc;
      }, this.summary);
    } else {
      this.allSteps = [];
      this.summary = {};
      this.areas = [];
    }
    this.completedOrSkippedAreasCount = entityDetails.completedRooms;
    this.areasCount = entityDetails.totalRooms;
    this.isArchived = entityDetails.isArchived;

    if (this.entityDetails.reviewRequiredByUserId && users?.size) {
      this.reviewer = users.get(entityDetails.reviewRequiredByUserId);
      this.completedByUser = users.get(entityDetails.completedBy);
    }
  }
}
