import { compact } from 'lodash';

import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { EnumerationItem } from '@shared/interfaces/enumeration-item';
import { RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

import { DynamicPropertyType, DynamicSchedule, RestDynamicInspectionModel } from './rest-inspections-model.interface';

export class DynamicInspectionModel {
  readonly id: number;
  readonly inspectionType: string;
  readonly propertyId: number;
  readonly propertyName: string;
  readonly portfolioId: number;
  readonly due: string;
  readonly notify: string;
  readonly step: string;
  readonly workflowStep: WorkflowStepEnumType;
  readonly notifyWhenDone: RestUserModel[];
  readonly assigneeUser: RestUserModel;

  constructor(
    public readonly entityDetails: RestDynamicInspectionModel,
    public readonly inspectionTemplate: RestTemplateModel,
    properties: RestPropertyModel[],
    private readonly workflowSteps: EnumerationItem[],
    readonly users: RestUserModel[]
  ) {
    this.id = entityDetails.id;
    this.inspectionType = inspectionTemplate?.name;
    this.workflowStep =
      entityDetails.dynamicallyScheduledOn === DynamicSchedule.MoveIn
        ? WorkflowStepEnumType.MoveIn
        : entityDetails.dynamicallyScheduledOn === DynamicSchedule.MoveOut
        ? WorkflowStepEnumType.MoveOut
        : entityDetails.dynamicallyScheduledOn === DynamicSchedule.MakeReady
        ? WorkflowStepEnumType.MakeReady
        : WorkflowStepEnumType.Notice;
    this.step = workflowSteps.find(s => s.enumerationValue === this.workflowStep).displayText;

    if (this.entityDetails.propertyType === DynamicPropertyType.Property) {
      this.propertyId = entityDetails.propertyId;
      this.portfolioId = entityDetails.portfolioId;
      this.propertyName = properties.find(p => p.id === entityDetails.propertyId)?.name;
    }
    if (this.entityDetails.propertyType === DynamicPropertyType.Portfolio) {
      //TODO: check if it has properties
      this.propertyId = -1;
      this.portfolioId = entityDetails.propertyOrPortfolioId;
    }
    this.due = this.buildDueString(entityDetails.dueDate, this.workflowStep);
    this.notify = this.buildDueString(entityDetails.createOrNotifyOn, this.workflowStep);

    const notifyWhenDoneIds = entityDetails.notifyWhenDoneUserIds?.split(',') || [];
    this.notifyWhenDone = compact(notifyWhenDoneIds.map(id => users.find(u => u.id === Number(id))));
    this.assigneeUser = users.find(el => el.id === entityDetails?.actualAssigneeId);
  }

  private buildDueString(days: number, step: WorkflowStepEnumType) {
    let stepName = this.step;
    if (step === WorkflowStepEnumType.Notice) {
      const s = this.workflowSteps.find(s => s.enumerationValue === WorkflowStepEnumType.MoveOut);
      stepName = s.displayText;
    }

    if (days < 0) {
      return `${Math.abs(days)}d before ${stepName.toUpperCase()}`;
    }
    if (days > 0) {
      return `${Math.abs(days)}d after ${stepName.toUpperCase()}`;
    }
    if (days === 0) {
      return `on ${stepName.toUpperCase()}`;
    }
  }
}
